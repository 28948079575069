import {
  IDENT_DATA_SELECTION,
  IDENT_FILTER_SELECTION,
} from '../constants/usersConstants'

import { AnyAction } from 'redux'


import {
  SET_USERS_DATA_SELECTION,
  SET_USERS_FILTER_SELECTION,
} from '../actions/usersActions'

const default_state = {
  [IDENT_DATA_SELECTION]: {},
  [IDENT_FILTER_SELECTION]: {},
}

export function users(state = default_state, action) {
  switch (action.type) {
    case SET_USERS_DATA_SELECTION:
      var new_state =
      {
        ...state,
        [IDENT_DATA_SELECTION]: action.payload
      }
      return new_state
    case SET_USERS_FILTER_SELECTION:
      var new_state =
      {
        ...state,
        [IDENT_FILTER_SELECTION]: action.payload
      }
      return new_state
    default:
      return state
  }
}
