import { lazy } from 'react'

// use lazy for better code splitting, a.k.a. load faster
const Dashboard = lazy(() => import('../pages/Dashboard'))
const SelectData = lazy(() => import('../pages/SelectDataTableau'))
const DownloadData = lazy(() => import('../pages/DownloadData'))
const Page404 = lazy(() => import('../pages/404'))
const Blank = lazy(() => import('../pages/Blank'))
const Profile = lazy(() => import('../pages/Profile'))
const Cart = lazy(() => import('../pages/Cart'))
const Grow = lazy(() => import('../pages/Grow'))
const TargetGroup = lazy(() => import('../pages/TargetGroup'))
const Will = lazy(() => import('../pages/Will'))
const GrowLists = lazy(() => import('../pages/GrowLists'))
const Export = lazy(() => import('../pages/Export'))
// add the page Bee
const Bee = lazy(() => import('../pages/Bee'))
const BeeDebug = lazy(() => import('../pages/BeeDebug'))
const DashboardBee = lazy(() => import('../pages/DashboardBee'))
const CartCoins = lazy(() => import('../pages/CartCoins'))

/**
 * ⚠ These are internal routes!
 * They will be rendered inside the app, using the default `containers/Layout`.
 * If you want to add a route to, let's say, a landing page, you should add
 * it to the `App`'s router, exactly like `Login`, `CreateAccount` and other pages
 * are routed.
 *
 * If you're looking for the links rendered in the SidebarContent, go to
 * `routes/sidebar.js`
 */

export const DASHBOARD = "dashboard"
export const DATASELECTION = "select-data"
export const DOWNLOAD = "download-data"
export const CREATEACCOUNT = "/create-account"
export const PROFILE = "profile"
export const TARGETGROUP = "targetGroup"
export const GROW = "grow"
export const CART = "cart"
export const LOGIN = "/login"
export const WILL = "will"
export const GROWLISTS = "growlists"
export const EXPORT = "export"
export const BEE = "bee"
export const CARTCOINS = "cartcoins"
export const BEEDEBUG = "beeDebug"
export const DASHBOARD_BEE = "dashboardBee"

const routes = [

  {
    path: '/' + BEEDEBUG, // the url
    component: BeeDebug, // view rendered
  },
  {
    path: '/' + CARTCOINS, // the url
    component: CartCoins, // view rendered
  },
   {
    path: '/' + DASHBOARD_BEE, // the url
    component: DashboardBee, // view rendered
   },
  {
    path: '/' + BEE, // the url
    component: Bee, // view rendered
  },
  {
    path: '/' + WILL, // the url
    component: Will, // view rendered
  },
  {
    path: '/' + EXPORT, // the url
    component: Export, // view rendered
  },
  {
    path: '/' + GROWLISTS, // the url
    component: GrowLists, // view rendered
  },
  {
    path: '/' + TARGETGROUP, // the url
    component: TargetGroup, // view rendered
  },
  {
    path: '/' + DASHBOARD, // the url
    component: Dashboard, // view rendered
  },
  {
    path: '/' + GROW, // the url
    component: Grow, // view rendered
  },
  {
    path: '/' + PROFILE, // the url
    component: Profile, // view rendered
  },
  {
    path: '/' + CART , // the url
    component: Cart, // view rendered
  },
  {
    path: '/' + DATASELECTION, // the url
    component: SelectData,
  },
  {
    path: '/' + DOWNLOAD, // the url
    component: DownloadData,
  },
  {
    path: '/404',
    component: Page404,
  },
  {
    path: '/blank',
    component: Blank,
  },
]

export default routes
