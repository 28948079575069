import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { auth } from '../helper/firebase/iniFirebase'

import {
    LOGIN,
    GROWLISTS,
    BEE,
    CREATEACCOUNT
} from '../routes'

function CheckLogin() {
    const history = useHistory()
    

    useEffect(() => {
        check_login()
    }, [])

    // function check_login() {
    //     auth().onAuthStateChanged((user) => {
    //         if (user) {
    //             history.push("/app/" + DASHBOARD)
    //         } else {
    //             history.push(LOGIN)
    //         }
    //     })
    // }

    function check_login() {
        auth().onAuthStateChanged((user) => {
            if (user) {
                console.log("user_id:", user.uid);
            }
            const location = history.location.pathname
            if (user && location == CREATEACCOUNT) {
                console.log(user.uid);
                history.push("/app/" + BEE)
            } else if (user && location == LOGIN) {
                history.push("/app/" + BEE)
            } else if (!user && location !== CREATEACCOUNT) {
                history.push(LOGIN)
            }
        })
    }

    return null
}

export default CheckLogin