import { combineReducers } from "redux";

import { users } from "./usersReducers";

//
const reducer =  combineReducers(
  {
    users,
  });

export default reducer
