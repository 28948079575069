import { IDENT_DATA_SELECTION, IDENT_USERS_STEP } from "../constants/usersConstants";

export const SET_USERS_DATA_SELECTION = "SET_USERS_DATA_SELECTION";
export const SET_USERS_FILTER_SELECTION = "SET_USERS_FILTER_SELECTION";

// probible good to delete
export function setUsersDataSelection(payload) {
  return {
    type: SET_USERS_DATA_SELECTION,
    payload,
  };
}
export function setUsersFilterSelection(payload) {
  return {
    type: SET_USERS_FILTER_SELECTION,
    payload,
  };
}