import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de: {
        translation: require('./assets/locales/de.json')
      }
    },
    lng: 'de',
    fallbackLng: 'de',

    interpolation: {
      escapeValue: false
    }
  })

export default i18n