import { createStore, applyMiddleware,compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './reducers'

const loggerMiddleware = createLogger()

//  const enhancers = compose(
//    window.devToolsExtension ? window.devToolsExtension() : f => f
//  );

 // const store = createStore(rootReducer, enhancers);


function configureStore(preloadedState) {
   return createStore(
     rootReducer,
     preloadedState,
     applyMiddleware(thunkMiddleware, loggerMiddleware)
   )
 }

 const store = configureStore();


 export default store
