import firebase from 'firebase/app';
// import 'firebase/database';
import 'firebase/functions';
// import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
// import 'firebase/storage'
// import { getFunctions, httpsCallable } from "firebase/functions";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  // databaseURL: 'hashtag-influxio.firebaseio.com',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

var functions = firebase.functions();
// if (window.location.hostname === "localhost" ||
//   window.location.hostname === "127.0.0.1"){
//     // firebase.functions().useEmulator("localhost", 5001);
//   }

// connectFunctionsEmulator(functions, "localhost", 5001);
//const getDispPurpData = functions.httpsCallable(functions, 'getDispPurpData');


// const firestore = firebase.firestore();
const db = firebase.database();
const auth = firebase.auth;
// const uid= firebase.auth().currentUser
// const storage = firebase.storage()
// const facebook_provider = new auth.FacebookAuthProvider();



// export { firebase, db,functions,auth, uid, storage, facebook_provider};
// export { functions, db, auth};
export { functions, auth, db};
