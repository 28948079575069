import React, { lazy, useState, useEffect } from 'react'

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'

import 'react-toastify/dist/ReactToastify.css';

import './assets/css/colors.css'
import './assets/css/custom.css'

import 'react-tooltip/dist/react-tooltip.css'

import CheckLogin from './components/CheckLogin'
import LoadingFull from './components/LoadingFull';

import { auth } from './helper/firebase/iniFirebase';

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

function App() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    iniAuth()
  }, [])


  function iniAuth() {
    auth().onAuthStateChanged(user => {
      if (user) {
        setIsLoading(false)
      } else {
        console.log("not authenticated")
        setIsLoading(false)
      }
    });
  }

  function render_content(){
    return (
    <>
      <Router>
        <CheckLogin />
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Place new routes over this */}
          <Route path="/app" component={Layout} />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>

    )
  }
  return (
    <>
    {isLoading ? <LoadingFull/> : render_content()}
  </>
  )
}

export default App
